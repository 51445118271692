import React, { useContext } from "react";
import { UserContext } from "../providers/UserProvider";
import { navigate } from "@reach/router";
import { Link } from "@reach/router";
import { auth } from "../firebase";

const UserHeader = () => {

  const user = useContext(UserContext);
  const {firstName, lastName, email} = user;

  const signOut = function() {
    auth.signOut().then(function(){
      navigate('/');
    }).catch(function(error) {
      // An error happened.
    });
  }

  return (
    <div>
      <header className="p-2 md:p-5 bg-red-100">
        <div className="flex items-center mx-auto">
          <div className="w-1/2 md:text-left">
              <Link to={"/"}><img className="md:inline-block block align-middle" id="j-logo" src="../images/julabo-logo-white.svg"/></Link>
              <h3 className="text-white inline-block align-middle md:ml-5 text-xl md:text-2xl"><strong>Resource</strong> Portal</h3>
          </div>
          <div className="w-1/2 text-right m-2 md:m-0">
            <span className="md:pr-2 text-white block md:inline">Welcome {firstName}!<span className="hidden md:inline"> |</span></span><a className="text-white cursor-pointer" onClick={signOut}>Sign out</a> <span className="text-white">|</span> <Link to={"/profile/"} className="text-white">Edit Profile</Link>
          </div>
        </div>
      </header>
    </div>
  )
};

export default UserHeader;