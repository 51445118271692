import React, { useContext } from "react";
import { Link } from "@reach/router";
import Loading from "./Loading";

class FolderPath extends React.Component {

  constructor(props){
    super(props);
    this.state = {
        error: null,
        isLoaded: false,
        tree: []
    }
    this.folderInfo = this.folderInfo.bind(this);
  }

  folderInfo() {

    if(this.props.fID) {
      this.setState({ isLoaded: false });

      var url = 'https://resources.julabo.us/box/folder.php?fid=' + this.props.fID;

      fetch(url)
      .then(res => res.json())
      .then(
        (result) => {

          var branches = [];

          result.path_collection.entries.forEach(function(item) {
              if(item.id !== '0') {
                  if(item.id == '111292943798') {
                      branches.push({id: item.id, name: 'Home'})
                  } else {
                      branches.push({id: item.id, name: item.name})
                  }
              }
          });

          if(result.id == '111292943798') {
              branches.push({id: result.id, name: 'Home'});
          } else {
              branches.push({id: result.id, name: result.name});
          }

          this.setState({
              isLoaded: true,
              tree: branches
          });

        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
    } else {

      var branches = [];
      branches.push({id: '111292943798', name: 'Home'})

      this.setState({
        isLoaded: true,
        tree: branches
      });

    }

  }

  componentDidMount() {
      this.folderInfo();
  }

  componentDidUpdate(prevProps, prevState) {
    if ( prevProps.fID !== this.props.fID) {
        this.folderInfo();
    }
  }

  render() {
    const { folder } = this.state;
    if(this.props.searchParam) {
      var searchText = <p className="inline-block mx-2 text-xs text-gray-700"><span className="mr-2">/</span> {'Results for "' + this.props.searchParam + '"'}</p>;
    }
    return (
      <div className="md:py-2 md:m-0 mb-2 folder-path">
          {this.state.tree.map(item => (
              <Link key={item.id} to={"/folder/" + item.id} className="inline-block mx-2 text-xs text-gray-700"><span className="mr-2">/</span> <i className="hover:underline not-italic">{item.name}</i></Link>
          ))}
          { searchText }
      </div>
    );
  }

}

export default FolderPath;