import React, { useContext } from "react";
import { navigate } from "@reach/router";

class FileSearch extends React.Component {

  constructor(props){
    super(props);
    this.state = {searchParam: ''};
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({searchParam: event.target.value});
  }

  handleSubmit(event) {
    navigate('/search/'+this.state.searchParam, { replace: true });
    event.preventDefault();
  }

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <input className="appearance-none rounded-l border md:w-1/2 w-3/4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" onChange={this.handleChange} type="text"/>
          <input className="btn rounded-r bg-black border border-black md:w-auto w-1/4 hover:bg-red-100 hover:border-red-100 hover:text-white leading-tight py-2 px-3 text-white self-center" type="submit" name="file-search" id="file-search" value="Search"/>
        </form>
      </div>
    );
  }

}

export default FileSearch;