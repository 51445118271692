import React, {useState} from "react";
import { Link, Match } from "@reach/router";
import { auth } from "../firebase";

const Header = () => {

    return (
        <header className="md:flex items-center md:p-5 p-4 bg-white shadow-md relative z-10">
            <div className="md:w-1/2 md:text-left">
                <Link to="/"><img className="md:mx-0 mx-auto" id="j-logo" src="images/julabo-logo.svg"/></Link>
            </div>
            <div className="md:w-1/2 md:text-right text-center">
                <Match path="/signUp">
                {props =>
                    props.match ? (
                        <div>
                            <span className="pr-2">Already have an account?</span> <Link to="/" className="bg-red-100 hover:bg-black text-white py-2 px-4 rounded">Sign In</Link>
                        </div>
                    ) : (
                        <div>
                            <span className="pr-2">Don't have an account?</span> <Link to="/signUp" className="bg-red-100 hover:bg-black text-white py-2 px-4 rounded">Sign Up</Link>
                        </div>
                    )
                }
                </Match>
            </div>
        </header>
    );
};

export default Header;