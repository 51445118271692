import React, { useContext } from "react";

class FileSize extends React.Component {

  constructor(props){
    super(props);
    this.state = {
        size: 0
    }
    this.sizeToMB = this.sizeToMB.bind(this);
  }

  sizeToMB() {
    
    var bytesToMegaBytes = (this.props.size / (1024*1024)).toFixed(1) + 'MB';

    this.setState({
        size: bytesToMegaBytes
    });

  }

  componentDidMount() {
      this.sizeToMB();
  }

  render() {
    return (
        <p>File Size: {this.state.size}</p>
      );    
  }

}

export default FileSize;