import React, { useState } from "react";
import { Link } from "@reach/router";
import Loading from "./Loading";

class Files extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            files: false
        }
        this.boxFiles = this.boxFiles.bind(this);
        this.folderIcon = this.folderIcon.bind(this);
        this.cardStyles = "items-center justify-start flex md:block bg-white rounded-lg shadow md:p-16 relative overflow-hidden";
        this.cardImageStyles = "p-4 md:p-0 md:m-auto md:mb-4";
        this.cardTitleStyles = "font-medium pr-4 md:p-0";
    }

    boxFiles() {

      var url = 'https://resources.julabo.us/box/index.php';

      if(this.props.fID) {
        url = 'https://resources.julabo.us/box/index.php?fid=' + this.props.fID;
      }

      if(this.props.searchParam) {
        url = 'https://resources.julabo.us/box/search.php?s=' + this.props.searchParam;
      }

      this.setState({ isLoaded: false });

      fetch(url)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            files: result
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );

    }

    folderIcon(folderID) {
      
      var folderICON = false;

      if(folderID == 111415303331) {
        folderICON = '../images/cannabis.jpg';
      }

      if(folderID == 111415222512) {
        folderICON = '../images/industry-onesheets.jpg';
      }

      if(folderID == 111407669804) {
        folderICON = '../images/product-manuals.jpg';
      }

      if(folderID == 111415637624) {
        folderICON = '../images/product-onesheets.jpg';
      }

      if(folderID == 111410742202) {
        folderICON = '../images/videos.jpg';
      }

      if(folderID == 111415161011) {
        folderICON = '../images/service.jpg';
      }

      if(folderID == 111415190709) {
        folderICON = '../images/tearsheets.jpg';
      }                        

      if(folderID == '133073613367') {
        folderICON = '../images/portal-guides-icon.jpg';
      }

      if(folderID == '133073727132') {
        folderICON = '../images/portal-magio-icon.jpg';
      }      

      if(folderICON) {
        return folderICON;
      } else {
        return '../images/folder.svg';
      }

    }

    componentDidMount() {
      this.boxFiles();
    }

    componentDidUpdate(prevProps, prevState) {

      if ( prevProps.fID !== this.props.fID) {
        this.boxFiles();
      }

      if ( prevProps.searchParam !== this.props.searchParam) {
        this.boxFiles();
      }

    }

    render() {
      const { error, isLoaded, files } = this.state;
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <Loading/>;
      } else if (this.state.files.length == 0) {
        return <div><p className="bg-neutral border border-black py-4 text-black text-center m-8">We're sorry but your search turned up zero results. Please try again.</p></div>;
      } else {
        return (
          <div className="bg-gray-100 py-6 md:py-12">
              <div className="container mx-auto">
                <div className="grid mx-2 md:grid-cols-3 md:gap-4 gap-2">
                  {files.map(item => (
                    <div className="portal-file-card text-center" key={item.id}>
                      { item.type == 'folder' ? <Link to={"/folder/" + item.id} className={this.cardStyles}><img className={this.cardImageStyles} src={this.folderIcon(item.id)}/><h2 className={this.cardTitleStyles}>{item.name}</h2></Link> : <Link to={"/file/" + item.id} className={this.cardStyles}><img className={this.cardImageStyles} src={item.file_thumbnail}/><h2 className={this.cardTitleStyles}>{item.name}</h2></Link> }
                    </div>
                  ))}
                </div>
              </div>
          </div>
        );
      }
    }
}

export default Files;