import React, { useContext } from "react";
import { UserContext } from "../providers/UserProvider";
import { navigate } from "@reach/router";
import Files from "./Files";
import FolderPath from "./FolderPath";
import UserHeader from "./UserHeader";
import FileSearch from "./FileSearch";

const ProfilePage = () => {
  return (
    <div>
      <UserHeader/> 
      <div className="bg-white shadow-md relative z-10">
        <div className="flex flex-wrap items-center mx-auto p-2">
          <div className="order-last flex-grow min-w-full mt-2 md:mt-0 md:min-w-0 md:order-first md:w-1/2 md:text-left">
            <FolderPath/>
          </div>
          <div className="md:w-1/2 flex-grow md:text-right">
            <FileSearch/>
          </div>
        </div>
      </div>           
      <Files/>  
    </div>
  ) 
};

export default ProfilePage;

