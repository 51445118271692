import React, { useContext } from "react";
import { Link, Match } from "@reach/router";

class Overlay extends React.Component {

  constructor(props){
    super(props);
    this.state = {
        visibility: false
    }

    this.setVisibility = this.setVisibility.bind(this);
    this.hideOverlay = this.hideOverlay.bind(this);

  }

  setVisibility() {
      this.setState({
          visibility: !this.props.visibility
      });
  }

  hideOverlay(e) {
      this.setState({
          visibility: false
      });
      this.props.onClose && this.props.onClose(e);
  }

  componentDidMount() {
      this.setVisibility();
  }

  componentDidUpdate(prevProps, prevState) {
    if ( prevProps.visibility !== this.props.visibility) {
        this.setVisibility();
    }
  }

  render() {
    if(!this.state.visibility) {
        return null;
    }
    return (
        <div className="bg-black bg-opacity-50 absolute w-full h-full top-0 left-0 z-20">
          <div class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center">
            <div class="modal-container bg-white w-11/12 md:max-w-3xl mx-auto rounded shadow-lg z-100">
              <div class="modal-content p-8 text-left relative z-100">
                <a onClick={this.hideOverlay} class="modal-close shadow-md bg-white cursor-pointer z-50 absolute rounded-full h-10 w-10 flex items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="20" height="20" viewBox="0 0 24 24" stroke-width="3" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z"/>
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                  </svg>
                </a>
                <div className="text-center">
                  <img src="images/benefits-img.jpg" className="mx-auto mb-6"/>
                  <h2 className="text-3xl leading-tight mb-6">You're going to love<br/>this partner portal!</h2>
                  <p className="mb-6">Get access to all the resources you need to understand JULABO's products and services and why they make a difference. Print and download product data sheets, industry one-sheets, product videos, application information, and much more.</p>
                </div>
                <div class="flex justify-center pt-2">
                  <Match path="/signUp">
                    {props =>
                        props.match ? (
                            <div></div>
                        ) : (
                            <Link to="/signUp" className="bg-red-100 hover:bg-black text-white py-2 px-4 rounded">Sign Up</Link>
                        )
                    }
                  </Match>                  
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }

}

export default Overlay;