import React, { useContext } from "react";

class Loading extends React.Component {

  constructor(props){
      super(props);
  }

  render() {
    return (
      <div>
        <p className="text-center text-xs p-4">Loading...</p>
      </div>    
    );
  }  

}

export default Loading;