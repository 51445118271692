import React, { useContext } from "react";
import FileSize from "./FileSize";
import Moment from 'react-moment';
import Loading from "./Loading";

class FileDetails extends React.Component {

  constructor(props){
    super(props);
    this.state = {
        error: null,
        isLoaded: false,
        file: []
    }
    this.getFile = this.getFile.bind(this);
    this.back = this.back.bind(this);
  }

  back() {
    window.history.back(); 
  }

  getFile() {

    this.setState({ isLoaded: false });

    var url = 'https://resources.julabo.us/box/file.php?fid=' + this.props.fileID;

    fetch(url)
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          isLoaded: true,
          file: result
        });
      },
      (error) => {
        this.setState({
          isLoaded: true,
          error
        });
      }
    );

  }

  componentDidMount() {
      this.getFile();
  }

  render() {
    const { error, isLoaded, file } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <Loading/>;
    } else {
      return (
        <div className="bg-white">
          <div className="shadow-md relative z-10">
            <div className="md:flex mx-auto bg-white">
              <div className="md:flex w-full">
                <a onClick={this.back} className="bg-black text-white text-2xl p-4 cursor-pointer flex items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-narrow-left" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#FFFFFF" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z"/>
                  <line x1="5" y1="12" x2="19" y2="12" />
                  <line x1="5" y1="12" x2="9" y2="16" />
                  <line x1="5" y1="12" x2="9" y2="8" />
                  </svg>                
                </a>
                <div className="md:w-1/2 md:text-left self-center p-4">
                  <h2 className="font-bold text-xl mb-2">{file.name}</h2>
                  <h3 className="mb-2">Uploaded <Moment format="ddd. MMM. D YYYY" date={file.created_at}></Moment></h3>
                  <p className="text-sm">{file.description}</p>
                </div>
              </div>
              <div className="md:w-1/2 md:text-right p-4">
                <a href={file.download_link} className="text-center btn bg-red-100 hover:bg-black p-3 md:inline-block md:w-auto md:mt-0 mt-4 block w-full text-white rounded mb-4">
                  <svg xmlns="http://www.w3.org/2000/svg" className="inline mr-2 icon icon-tabler icon-tabler-download" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#FFFFFF" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z"/>
                  <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
                  <polyline points="7 11 12 16 17 11" />
                  <line x1="12" y1="4" x2="12" y2="16" />
                  </svg>                  
                  Download
                </a>
                <FileSize size={file.size}/>
              </div>
            </div>
          </div>
          <div className="bg-gray-100 py-10 mx-auto text-center">
            <img className="shadow m-auto mb-4" src={file.file_image}/>
          </div>
        </div>
      );
    }
  }

}

export default FileDetails;