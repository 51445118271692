import React, { useContext } from "react";
import { Router } from "@reach/router";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import File from "./File";
import Folder from "./Folder";
import Search from "./Search";
import Profile from "./Profile";
import UserProvider from "../providers/UserProvider";
import ProfilePage from "./ProfilePage";
import { UserContext } from "../providers/UserProvider";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";

function Application() {
  const user = useContext(UserContext);
  return (
      user ?
        <Router>
          <ProfilePage path="/" />
          <File path="file/:fileID" />
          <Folder path="folder/:folderID" />
          <Search path="search/:searchParam" />
          <Profile path="profile" user={user}/>
          <ResetPassword path="resetPassword" />
        </Router>
      :
        <Router>
          <SignUp path="signUp" />
          <SignIn path="/" />
          <ForgotPassword path="forgotPassword" />
          <ResetPassword path="resetPassword" />
        </Router>
  );
}

export default Application;
