import React, { useContext } from "react";
import { auth, firestore } from "../firebase";
import { navigate } from "@reach/router";
import { Link } from "@reach/router";
import { UserContext } from "../providers/UserProvider";
import UserHeader from "./UserHeader";
import Header from "./Header";
import { useLocation } from "@reach/router"
import { parse } from "query-string"

class ResetPassword extends React.Component {

  constructor(props){

      super(props);

      this.state = {
        urlParams: false,
        password: false,
        error: null,
        sucess: null
      }

      this.setParams = this.setParams.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.handleReset = this.handleReset.bind(this);

  }

  setParams() {
    this.setState({
      urlParams: parse(this.props.location.search)
    });
  }

  componentDidMount() {
    this.setParams();
  }

  handleChange(event) {
    this.setState({password: event.target.value});
  }

  handleReset(event) {

    event.preventDefault();

    var accountEmail;

    var actionCode = this.state.urlParams.oobCode;
    var newPass = this.state.password;
    var r = this;

    // Verify the password reset code is valid.

    auth.verifyPasswordResetCode(actionCode).then(function(email) {
      auth.confirmPasswordReset(actionCode, newPass).then(function(resp) {
        r.setState({
          success: true,
          error: null
        });
      }).catch(function(error) {
        r.setState({
          error: error.message,
          success: false
        });
      });
    }).catch(function(error) {
      r.setState({
        error: error.message,
        success: false
      });
    });
  }

  render() {
    return (
        <div>
          <Header />
          <div className="bg-grey-100">
              <div className="mx-auto w-11/12 md:w-1/2 py-8 px-4 md:px-8">
                {this.state.success && (
                  <div className="py-4 bg-success border border-black text-black text-center mb-3 mx-12">Password changed please <Link to="/" className="text-black hover:no-underline underline">Sign In</Link></div>
                )}
                {this.state.error !== null && (
                  <div className="py-4 bg-error border border-black text-black text-center mb-3 mx-12">
                    {this.state.error}
                  </div>
                )}
                <form onSubmit={this.handleReset}>
                    <p className="text-black text-center mb-6">Enter your new password below</p>
                    <input type="password" name="userEmail" id="userEmail" className="border my-1 p-1 w-full rounded mb-4"  onChange={this.handleChange} />
                    <button className="btn bg-black hover:bg-white hover:text-black w-auto p-2 text-white rounded text-center mb-4">Reset Password</button>
                </form>
              </div>
          </div>
        </div>
    );
  }

}

export default ResetPassword;