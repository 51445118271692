import React, { useContext } from "react";
import { auth, firestore } from "../firebase";
import { navigate } from "@reach/router";
import { UserContext } from "../providers/UserProvider";
import UserHeader from "./UserHeader";

class Profile extends React.Component {

  constructor(props){

      super(props);

      this.state = {
        'email': this.props.user.email,
        'firstName': this.props.user.firstName,
        'lastName': this.props.user.lastName,
        'phoneNumber': this.props.user.phoneNumber,
        'placeOfWork': this.props.user.placeOfWork,
        'repType': this.props.user.repType,
        'message': false
      }

      this.updateProfile = this.updateProfile.bind(this);
      this.changePassword = this.changePassword.bind(this);
      this.handleChange = this.handleChange.bind(this);

  }

  updateProfile(event) {

      this.setState({
        message: false
      })

      var userRef = firestore.doc(`users/${this.props.user.uid}`);
      var updated = false;

      userRef.update(this.state).then(
        (result) => {
          this.setState({
            message: { type: 'bg-success', text: 'Profile Updated' }
          });
          console.log(this.state.message);
          setTimeout(() => {
            this.setState({
              message: false
            });
          }, 3000);
        },
        (error) => {
          this.setState({
            message: { type: 'bg-error', text: 'Problem Updating Profile' }
          });
        }
      );

      event.preventDefault();
  }

  handleChange(event) {

    var id = event.target.id;
    var val = event.target.value;

    this.setState({
        [id]: val
    });

  }

  changePassword(event) {

    this.setState({
      message: false
    });

    auth.sendPasswordResetEmail(this.props.user.email).then(() => {
      this.setState({
        message: { type: 'bg-success', text: 'Password Reset Email Sent' }
      });
      setTimeout(() => {
        this.setState({
          message: false
        });
      }, 3000);
    }).catch((error) => {
      this.setState({
        message: { type: 'bg-error', text: 'Problem Resetting Password' }
      });
    });

    event.preventDefault();
  }

  render() {
    return (
      <div>
        <UserHeader/>
          <div className="bg-gray-100 md:py-12 min-h-screen">
              <div className="container mx-auto">
                <div className="mx-auto w-11/12 md:w-3/4 py-8 px-4 md:px-8">
                  <form className="md:px-12" onSubmit={this.updateProfile}>
                    <h2 className="text-xl font-semibold mb-4">Profile Details:</h2>
                    {this.state.message !== false && (
                      <div className={ this.state.message.type + ' border border-black py-4 w-full text-black text-center mb-3'}>
                        {this.state.message.text}
                      </div>
                    )}                    
                    <div className="md:flex md:mb-4">
                      <div className="md:w-1/2 w-full pr-2">
                        <label htmlFor="firstName" className="block text-black">First Name:</label>
                        <input className="w-full appearance-none rounded border py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" name="firstName" id="firstName" onChange={this.handleChange} defaultValue={this.props.user.firstName}/>
                      </div>
                      <div className="md:w-1/2 w-full pr-2">
                        <label htmlFor="lastName" className="block text-black">Last Name:</label>
                        <input className="w-full appearance-none rounded border py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" name="lastName" id="lastName" onChange={this.handleChange} defaultValue={this.props.user.lastName}/>
                      </div>
                    </div>
                    <div className="md:flex md:mb-4">
                      <div className="md:w-1/2 w-full pr-2">
                        <label htmlFor="phoneNumber" className="block text-black">Phone Number:</label>
                        <input className="w-full appearance-none rounded border py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" name="phoneNumber" id="phoneNumber" onChange={this.handleChange} defaultValue={this.props.user.phoneNumber}/>
                      </div>
                      <div className="md:w-1/2 w-full pr-2">
                        <label htmlFor="placeOfWork" className="block text-black">Company Name:</label>
                        <input className="w-full appearance-none rounded border py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" name="placeOfWork" id="placeOfWork" onChange={this.handleChange} defaultValue={this.props.user.placeOfWork}/>
                      </div>
                    </div>
                    <div className="md:flex md:mb-4">                      
                      <div className="md:w-1/2 w-full pr-2">
                        <label htmlFor="repType" className="block text-black">Job Title:</label>
                        <input className="w-full appearance-none rounded border py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" name="repType" id="repType" onChange={this.handleChange} defaultValue={this.props.user.repType}/>
                      </div>
                    </div>
                    <div className="text-center">
                      <input className="btn bg-black hover:bg-red-100 p-2 m-4 text-white rounded self-center cursor-pointer" type="submit" name="submit" id="submit" value="Update Profile"/>
                    </div>
                  </form>
                  <div className="md:px-12 my-8">
                    <hr/>
                  </div>
                  <div className="md:px-12">
                      <h2 className="text-xl font-semibold mb-4">Reset Password:</h2>
                      <p className="mb-4">We'll email you instructions on how to update your password.</p>
                      <button className="bg-red-100 hover:bg-black p-2 text-white rounded" onClick={this.changePassword}>Reset Password</button>
                  </div>
                </div>
            </div>
        </div>
      </div>
    );
  }

}

export default Profile;