import React, { useContext, useState } from "react";
import { navigate } from "@reach/router";
import { Link } from "@reach/router";
import Header from "./Header";
import Overlay from "./Overlay";
import { auth, signInWithGoogle, generateUserDocument } from "../firebase";

const SignUp = () => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [placeOfWork, setPlaceOfWork] = useState("");
  const [repType, setRepType] = useState("");
  const [error, setError] = useState(null);
  const [showOverlay, setShowOverlay] = useState(true);

  const setOverlayStatus = (event) => {
    
    event.preventDefault();

    setShowOverlay(!showOverlay);

  };  

  const createUserWithEmailAndPasswordHandler = async (event, email, password, confirmPassword, message) => {

    event.preventDefault();

    if(password == confirmPassword) {
      
      setError(null);

      try {
        
        const {user} = await auth.createUserWithEmailAndPassword(email, password);

        var userDoc = await generateUserDocument(user, {firstName, lastName, phoneNumber, placeOfWork, repType});

        if(userDoc) {
          navigate('/');
        }

      } catch(error) {
        
        setError(error.message);

        document.getElementById('message').scrollIntoView({ behavior: 'smooth', block: 'start' })

      }

    } else {
      
      setError("Password Don't Match");

    }

  };

  const onChangeHandler = event => {
    const { name, value } = event.currentTarget;

    if (name === "userEmail") {
      setEmail(value);
    } else if (name === "userPassword") {
      setPassword(value);
    } else if (name === "confirmPassword") {
      setConfirmPassword(value);      
    } else if (name === "firstName") {
      setFirstName(value);
    } else if (name === "lastName") {
      setLastName(value);
    } else if (name === "phoneNumber") {
      setPhoneNumber(value);
    } else if (name === "placeOfWork") {
      setPlaceOfWork(value);
    } if (name === "repType") {
      setRepType(value);
    }

  };

  return (
    <div>
      <Overlay onClose={setOverlayStatus} visibility={showOverlay}/>
      <Header />
      <div className="bg-red-100">
        <div className="container mx-auto max-w-md">
          <Link to="/"><img className="px-12 pt-10 mb-2" src="images/julabo-logo-white.svg" alt="Julabo"/></Link>
          <hr className="mx-12 mt-5"/>
          <h1 className="text-4xl text-center text-white"><span className="font-bold">Resource</span> Portal</h1>
        </div>
        <div className="mx-auto w-11/12 md:w-3/4 py-8 px-4 md:px-8">
          {error !== null && (
            <div className="py-4 mb-8 bg-error border border-black w-full text-black text-center mb-3" id="message">
              {error}
            </div>
          )}
          <p className="text-white text-center mb-6">Thanks for showing interest in the JULABO Resource Portal. We’d like to learn a bit about you so that we can better serve you the resources you need to be a great representative of JULABO.</p>
          <form className="md:px-12" onSubmit={event => {createUserWithEmailAndPasswordHandler(event, email, password, confirmPassword);}}>
            <div className="md:flex mb-4">
              <div className="md:w-1/2 md:pr-2">
                <label htmlFor="firstName" className="block text-white">First Name:</label>
                <input required type="text" className="border my-1 p-1 w-full rounded" name="firstName" value={firstName} placeholder="" id="firstName" onChange={event => onChangeHandler(event)} />
              </div>
              <div className="md:w-1/2 md:pl-2">
                <label htmlFor="lastName" className="block text-white">Last Name:</label>
                <input required type="text" className="border my-1 p-1 w-full rounded" name="lastName" value={lastName} placeholder="" id="lastName" onChange={event => onChangeHandler(event)} />
              </div>
            </div>
            <div className="md:flex mb-4">
              <div className="md:w-1/2 md:pr-2">
                <label htmlFor="userEmail" className="block text-white">Your Email:</label>
                <input required type="email" className="border my-1 p-1 w-full rounded" name="userEmail" value={email} placeholder="" id="userEmail" onChange={event => onChangeHandler(event)} />
              </div>
              <div className="md:w-1/2 md:pl-2">
                <label htmlFor="phoneNumber" className="block text-white">Phone Number:</label>
                <input required type="text" className="border mt-1 mb-3 p-1 w-full rounded" name="phoneNumber" value={phoneNumber} id="phoneNumber" onChange={event => onChangeHandler(event)} />
              </div>
            </div>
            <div className="md:flex mb-4">
              <div className="md:w-1/2 md:pr-2">
                <label htmlFor="userPassword" className="block text-white">Password:</label>
                <input required type="password" className="border mt-1 mb-3 p-1 w-full rounded" name="userPassword" value={password} placeholder="" id="userPassword" onChange={event => onChangeHandler(event)} />
              </div>
              <div className="md:w-1/2 md:pl-2">
                <label htmlFor="userPassword" className="block text-white">Confirm Password:</label>
                <input required type="password" className="border mt-1 mb-3 p-1 w-full rounded" name="confirmPassword" value={confirmPassword} placeholder="" id="confirmPassword" onChange={event => onChangeHandler(event)} />
              </div>
            </div>
            <div className="md:flex mb-4">
              <div className="md:w-1/2 md:pr-2">
                <label htmlFor="placeOfWork" className="block text-white">Company Name:</label>
                <input required type="text" className="border mt-1 mb-3 p-1 w-full rounded" name="placeOfWork" value={placeOfWork} id="placeOfWork" onChange={event => onChangeHandler(event)} />
              </div>
              <div className="md:w-1/2 md:pl-2">
                <label htmlFor="repType" className="block text-white">Job Title:</label>
                <input required type="text" className="border mt-1 mb-3 p-1 w-full rounded" name="repType" value={repType} id="repType" onChange={event => onChangeHandler(event)} />
              </div>
            </div>
            <div className="text-center">
              <input type="submit" value="Sign Up" className="btn bg-black hover:bg-white hover:text-black w-1/2 p-2 text-white rounded self-center"/>
            </div>
          </form>
           <p className="text-white text-center mt-6">Already have an account? <Link to="/" className="underline hover:no-underline">Sign In</Link></p>
        </div>
        <div className="bg-red-200 text-center p-12">
          <p className="text-white"><a href="" onClick={(event) => setOverlayStatus(event)} className="underline hover:no-underline">Learn More</a> about the benefits<br/>before signing up.</p>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
