import React, { useContext } from "react";
import { navigate } from "@reach/router";
import Files from "./Files";
import FileDetails from "./FileDetails";
import UserHeader from "./UserHeader";

class File extends React.Component {

  constructor(props){
      super(props);
  }

  render() {
    return (
      <div>
        <UserHeader/>
        <FileDetails fileID={this.props.fileID}/>
      </div>
    );
  }

}

export default File;