import React, {useState} from "react";
import { Link } from "@reach/router";
import Header from "./Header";
import Overlay from "./Overlay";
import { auth } from "../firebase";


const SignIn = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [showOverlay, setShowOverlay] = useState(true);

    const signInWithEmailAndPasswordHandler = (event,email, password) => {
        event.preventDefault();
        auth.signInWithEmailAndPassword(email, password).catch(error => {
          setError("Error signing in with password and email!");
        });
      };

    const onChangeHandler = (event) => {
        const {name, value} = event.currentTarget;

        if(name === 'userEmail') {
          setEmail(value);
        } else if(name === 'userPassword'){
          setPassword(value);
        }
    };

    const setOverlayStatus = (event) => {
      
      event.preventDefault();

      setShowOverlay(!showOverlay);

    };


  return (
    <div id="j-sign-in" className="h-full">
      <Overlay onClose={setOverlayStatus} visibility={showOverlay}/>
      <Header />
      <div className="md:p-20 p:4">
        <div className="bg-red-100 bg-opacity-75 container mx-auto max-w-md rounded">
        <Link to="/"><img className="px-12 pt-10 mb-2" src="images/julabo-logo-white.svg" alt="Julabo"/></Link>
        <hr className="mx-12 mt-5"/>
        <h1 className="text-4xl text-center text-white"><span className="font-bold">Resource</span> Portal</h1>
        <div className="mt-5">
          {error !== null && (
            <div className="py-4 bg-error border border-black text-black text-center mb-3 mx-12">
              {error}
            </div>
          )}
          <form className="px-12">
            <label htmlFor="userEmail" className="block text-white">
              Your Email:
            </label>
            <input
              type="email"
              className="border my-1 p-2 w-full rounded"
              name="userEmail"
              value = {email}
              placeholder=""
              id="userEmail"
              onChange = {(event) => onChangeHandler(event)}
            />
            <label htmlFor="userPassword" className="block text-white">
              Password:
            </label>
            <input
              type="password"
              className="border mt-1 mb-3 p-2 w-full rounded"
              name="userPassword"
              value = {password}
              placeholder=""
              id="userPassword"
              onChange = {(event) => onChangeHandler(event)}
            />
            <button className="bg-black hover:bg-white hover:text-black w-full py-2 text-white rounded" onClick = {(event) => {signInWithEmailAndPasswordHandler(event, email, password)}}>
              Sign In
            </button>
          </form>
          <p className="text-center my-5 text-white">
            Don't have an account? <Link to="signUp" className="text-white hover:text-black underline">Sign Up</Link>
          </p>
          <p className="text-center my-5 text-white">
            <Link to="forgotPassword" className="text-white hover:text-black underline">
              Forgot Password?
            </Link>
          </p>
          <div className="bg-black bg-opacity-25 md:px-10 px-5 py-5 rounded rounded-t-none">
            <p className="text-white text-center md:px-10 px-5"><a href="" onClick={(event) => setOverlayStatus(event)} className="text-white hover:text-black underline">Learn More</a> about the benefits before signing up.</p>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;