import React, { useContext } from "react";
import { navigate } from "@reach/router";
import Files from "./Files";
import FolderPath from "./FolderPath";
import UserHeader from "./UserHeader";
import FileSearch from "./FileSearch";

class Search extends React.Component {

  constructor(props){
      super(props);
  }

  render() {
    return (
      <div>
        <UserHeader/>
        <div className="bg-white shadow-md relative z-10">
          <div className="md:flex items-center mx-auto p-2">
            <div className="md:w-1/2 md:text-left">
              <FolderPath searchParam={this.props.searchParam}/>
            </div>
            <div className="md:w-1/2 md:text-right">
              <FileSearch/>
            </div>
          </div>
        </div>        
        <Files searchParam={this.props.searchParam}/>
      </div>
    );
  }

}

export default Search;