import React, { useState, useContext } from "react";
import { auth } from "../firebase";
import { UserContext } from "../providers/UserProvider";
import { Link } from "@reach/router";
import Header from "./Header";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [emailHasBeenSent, setEmailHasBeenSent] = useState(false);
  const [error, setError] = useState(null);

  const onChangeHandler = event => {
    const { name, value } = event.currentTarget;

    if (name === "userEmail") {
      setEmail(value);
    }
  };

  const sendResetEmail = event => {
    event.preventDefault();
    auth.sendPasswordResetEmail(email).then(() => {
          setError(null);
          setEmailHasBeenSent(true);
          setTimeout(() => {setEmailHasBeenSent(false)}, 3000);
      }).catch((error) => {
        setError(error.message);
      });
  };
  return (
    <div>
      <Header />
      <div className="bg-red-100">
        <div className="container mx-auto max-w-md">
          <img className="px-12 pt-10 mb-2" src="images/julabo-logo-white.svg" alt="Julabo"/>
          <hr className="mx-12 mt-5"/>
          <h1 className="text-4xl text-center text-white"><span className="font-bold">Resource</span> Portal</h1>
        </div>
        <div className="mx-auto w-11/12 md:w-1/2 py-8 px-4 md:px-8">
          <form action="">
            {emailHasBeenSent && (
              <div className="py-4 bg-success border border-black text-black text-center mb-3 mx-12">Password Reset Email Sent</div>
            )}
            {error !== null && (
              <div className="py-4 bg-error border border-black text-black text-center mb-3 mx-12">
                {error}
              </div>
            )}
            <p className="text-white text-center mb-6">Enter your email below and we'll email you instructions on how to reset your password.</p>
            <hr className="mb-4"/>
            <input type="email" name="userEmail" id="userEmail" value={email} placeholder="Your Email" onChange={onChangeHandler} className="border my-1 p-1 w-full rounded mb-4" />
            <button className="btn bg-black hover:bg-white hover:text-black w-auto p-2 text-white rounded text-center mb-4" onClick={event => { sendResetEmail(event); }} >Send me a reset link</button>
          </form>
          <Link to="/" className="my-2 text-white hover:text-black text-center">&larr; back to sign in page</Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
